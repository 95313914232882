/* eslint-disable max-classes-per-file */
import { observable } from 'mobx'
import { persist, create } from 'mobx-persist'

class Sections {
    @persist('list') @observable value = []

    @observable clear() {
        this.value = []
    }
}
class Token {
    @persist @observable value = ''

    @observable clear() {
        this.value = ''
    }
}
class User {
    @persist('object') @observable value = {}

    @observable clear() {
        this.value = {}
    }
}

class Notificaitons {
    @persist('object') @observable value = {}

    @observable clear() {
        this.value = {}
    }
}

// const store = (window.store = new Store());
const userStore = new User()
const tokenStore = new Token()
const sectionsStore = new Sections()
const notificaitons = new Notificaitons()
const hydrate = create({
    storage: localStorage,
    jsonify: true,
})
hydrate('dar-user', userStore).then(() => {})
hydrate('dar-token', tokenStore).then(() => {})
hydrate('dar-sections', sectionsStore).then(() => {})

export default {
    userStore,
    tokenStore,
    sectionsStore,
    notificaitons,
}
