import { lazy } from 'react'; // ela aw waxte calle aw componenta daka ka requeste lo dache

const options = [
  {
    key: Math.random(),
    path: '/',
    component: lazy(() => import('../dashboard/Main')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/users',
    component: lazy(() => import('../user/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/cards',
    component: lazy(() => import('../cards/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/tree',
    component: lazy(() => import('../tree/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/map',
    component: lazy(() => import('../map/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/city',
    component: lazy(() => import('../city/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/plantedTree',
    component: lazy(() => import('../plantedTree/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/voluantry',
    component: lazy(() => import('../voluantry/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/newsFeed',
    component: lazy(() => import('../newsFeed/List')),
    exact: true,
  },
];
export default options;
