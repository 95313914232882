/* eslint-disable react/destructuring-assignment */
import React, { Suspense } from 'react';
import { observer, inject } from 'mobx-react';
import {
  Switch, Route, Link, withRouter,
} from 'react-router-dom';
import {
  Layout, Menu, Button, Col, Modal as AntdModal,
  Avatar, Typography, Space, Dropdown,
} from 'antd';
import {
  MenuOutlined,
  UserOutlined,
  HeatMapOutlined,
  PoweroffOutlined,
  DownOutlined,
  MacCommandOutlined,
  CreditCardOutlined,
  RadarChartOutlined,
} from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import Loading from '../basic/Loading';
import routes from './dashboardRoutes';
import largeLogo from '../../assets/images/tree.png';
import i18n from '../../i18n';
import './dashboard.css';

const {
  Header, Content, Footer, Sider,
} = Layout;
@inject('userStore', 'tokenStore', 'sectionsStore')
@observer
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = () => ({
      collapsed: false,
      pic: '',
      windowsWidth: 0,
      defualtMenu: 'visitlist',
      openKeys: [],
    });
    this.state = this.initialState();

    this.toggleSidebar = () => {
      this.setState((prevState) => ({
        collapsed: !prevState.collapsed,
      }));
    };
    this.logout = () => {
      const { userStore, tokenStore, sectionsStore } = this.props;
      Promise.all([
        userStore.clear(),
        tokenStore.clear(),
        sectionsStore.clear(),
      ]).then(() => { });
    };
    this.warning = () => {
      const scope = this;
      AntdModal.confirm({
        centered: true,
        title: i18n.t('Are You Sure'),
        content: i18n.t('You are abuot to logout of the system!'),
        okText: i18n.t('Logout'),
        onOk() {
          scope.logout();
        },
        onCancel() { },
      });
    };

    this.opentRateModal = async () => {
      this.rateModal.current.click();
    };

    this.rateModal = React.createRef();
  }

  // componentDidMount() {
  //   const { userStore } = this.props;
  //   // if (userStore.value.privilege === undefined) {
  //   //   this.setState({ access: {} });
  //   // }
  //   const scope = this;
  //   superagent.get('/user/me').end((err, res) => {
  //     if (!err) {
  //       const { body } = res;

  //       userStore.value = body;
  //     } else if (err && err.status === 401) {
  //       AntdModal.error({
  //         centered: true,
  //         title: 'Unauthorised!',
  //         content: 'This account is unauthorised, You need to logout!',
  //         okText: 'Procced',
  //         onOk() {
  //           scope.logout();
  //         },
  //       });
  //     }
  //   });
  // }

  render() {
    const { collapsed } = this.state;
    const { location, userStore } = this.props;
    const userName = userStore.value.fullname;

    let removeBg;
    if (location && location.pathname === '/') {
      removeBg = true;
    } else removeBg = false;

    const menu = (

      <Menu style={{ top: '-10px' }}>
        <Menu.Divider />
        <Menu.Item key="users"
          onClick={this.warning}
          icon={(
            <PoweroffOutlined style={{
              color: '#d16e17', paddingRight: '0.5px',
            }}
            />
)}
        >
          <span>{i18n.t('Logout')}</span>
        </Menu.Item>
      </Menu>
    );
    // const onOpenChange = (keys) => {
    //   const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    //   if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
    //     this.setState({ openKeys: keys });
    //   } else {
    //     this.setState({ openKeys: latestOpenKey ? [latestOpenKey] : [] });
    //   }
    // };
    const lang = (
      <Menu>
        <Menu.Item 
          onClick={() => {
            i18n.changeLanguage('ar');
            window.location.reload();
          }}
          key="4"
        >
          عربي
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            i18n.changeLanguage('en');
            window.location.reload();
          }}
          key="2"
        >
          English
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            i18n.changeLanguage('ku');
            window.location.reload();
          }}
          key="3"
        >
          کوردی
        </Menu.Item>
      </Menu>
    );
    const sider = (
      <Sider
        theme="dark"
        style={{
          overflow: 'auto',
          background: '#FFF',
        }}
        width={collapsed ? 0 : 240}
      >
        <div
          className="logo"
          style={{
            padding: 0,
            textAlign: 'center',
            background: '#FFF',
          }}
        >
          <img
            alt="Dar Logo"
            style={{ width: '85px', height: '85px', paddingTop: '20px' }}
            src={largeLogo}
          />
        </div>
        <Menu
          // openKeys={openKeys}
          // onOpenChange={onOpenChange}
          theme="white"
          selectedKeys={[location.pathname]}
          mode="inline"
          style={{ background: '#FFF', marginTop: 20 }}
        >
            <Menu.Item key="/dashboard">
            <CreditCardOutlined style={{ paddingRight: '11px' }} />
            {i18n.t('Dashboard')}
            <Link to="/" />
          </Menu.Item>
          <Menu.Item key="/users">
            <CreditCardOutlined style={{ paddingRight: '11px' }} />
            {i18n.t('Users')}
            <Link to="/users" />
          </Menu.Item>
          <Menu.Item key="/cards">
            <CreditCardOutlined style={{ paddingRight: '11px' }} />
            {i18n.t('Cards')}
            <Link to="/cards" />
          </Menu.Item>
          <Menu.Item key="/tree">
            <MacCommandOutlined style={{ paddingRight: '11px' }} />
            {i18n.t('Tree')}
            <Link to="/tree" />
          </Menu.Item>
          <Menu.Item key="/map">
            <HeatMapOutlined style={{ paddingRight: '11px' }} />
            {i18n.t('Map')}
            <Link to="/map" />
          </Menu.Item>
          <Menu.Item key="/city">
            <HeatMapOutlined style={{ paddingRight: '11px' }} />
            {i18n.t('City')}
            <Link to="/city" />
          </Menu.Item>
          <Menu.Item key="/plantedTree">
            <RadarChartOutlined style={{ paddingRight: '11px' }} />
            {i18n.t('Planted Tree')}
            <Link to="/plantedTree" />
          </Menu.Item>
          <Menu.Item key="/voluantry">
            <RadarChartOutlined style={{ paddingRight: '11px' }} />
            {i18n.t('voluantry')}
            <Link to="/voluantry" />
          </Menu.Item>
          <Menu.Item key="/newsfeed">
            <RadarChartOutlined style={{ paddingRight: '11px' }} />
            {i18n.t('newsfeed')}
            <Link to="/newsfeed" />
          </Menu.Item>
        </Menu>
      </Sider>
    );
    const header = (
      <Header
        className="site-layout-background"
        style={{ padding: '0 12px', display: 'inherit' }}
      >
        <Col span={12} style={{ textAlign: 'start' }}>
          <Button
            type="link"
            icon={
              <MenuOutlined style={{ fontSize: 15, color: '#1f2532' }} />
                  }
            onClick={this.toggleSidebar}
          />
        </Col>
        <Col span={12} style={{ textAlign: 'end' }}>
          <Space size="middle">
            <Dropdown
              overlay={lang}
            >
              <Button type="dashed">
                {i18n.language === 'ar' ? 'العربية' : null}
                {' '}
                {i18n.language === 'ku' ? 'کوردی-سۆرانی' : null}
                {i18n.language === 'en' ? 'English' : null}
                <DownOutlined />
              </Button>
            </Dropdown>
            <Dropdown overlay={menu} trigger="click">
              <Space>
                <Avatar
                  size="small"
                  // src={coverpic}
                  icon={<UserOutlined />}
                />
                <span
                  style={{
                    cursor: 'pointer',
                    fontFamily: 'Inter',
                    textTransform: 'capitalize',
                  }}
                >
                  {userName?.toLowerCase()}
                </span>
              </Space>
            </Dropdown>
          </Space>
        </Col>
      </Header>
    );
    const content = (
      <Content style={{ margin: '16px 16px', marginBottom: 0 }}>
        <div
          className="site-layout-background"
          style={{
            minHeight: 500,
            padding: removeBg ? 0 : 24,
            background: removeBg ? 'none' : undefined,
          }}
        >
          <Suspense fallback={<Loading />}>
            <Switch>
              {routes.map((route) => (
                <Route
                  exact={route.exact}
                  key={route.key}
                  path={`${route.path}`}
                >
                  <route.component />
                </Route>
              ))}
            </Switch>
          </Suspense>
        </div>
      </Content>
    );
    const footer = (
      <Footer style={{ textAlign: 'center' }}>
        <Col span={24}>
          <Typography.Paragraph align="center" type="secondary">
            <span>
              {i18n.t('Designed and developed by')}
              {' '}
            </span>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://kernel.krd"
              title="Kernel"
            >
              {i18n.t('Kernel')}

            </a>
          </Typography.Paragraph>
        </Col>
      </Footer>
    );
    return (
      <>

        <Layout style={{ minHeight: '100vh' }}>
          {sider}
          <Layout
            className="site-layout"
            style={{
              transition: 'all 0.2s',
            }}
          >
            {header}
            {content}
            {footer}
          </Layout>
        </Layout>
      </>
    );
  }
}
export default withTranslation()(
  withRouter((props) => <Dashboard {...props} />),
);
